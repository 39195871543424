<ng-template #template>
  <th [style]="headerStyle">
    <div class="title-header-cont">
      <span (click)="order()" class="header-title pointer">{{
        columnName | localize
      }}</span>
      <i
        style="font-size: 11px"
        *ngIf="currentOrder == columnName"
        [ngClass]="orderDirection == 0 ? 'fa-sort-asc' : 'fa-sort-desc'"
        class="fas"
      >
      </i>
      <div
        class="btn-group"
        dropdown
        (isOpenChange)="onOpenChange($event)"
        #filterDropdown="bs-dropdown"
        [insideClick]="true"
      >
        <i
          style="font-size: 11px"
          *ngIf="filter"
          dropdownToggle
          [ngClass]="isFilterExecutedWithValue ? 'filter-active' : ''"
          class="fas fa-filter pointer"
        ></i>
        <ul *dropdownMenu class="dropdown-menu" style="width: 250px">
          <li class="pl-4 pr-4 pt-2 pb-2">
            <label>{{ "Filter" | localize }}:</label>
            <div class="input-cont">
              <input
                *ngIf="mustShowTextInput()"
                #filterText
                (keyup.enter)="executeFilter()"
                (keyup.esc)="clearAndCloseFilterDropdown()"
                type="text"
                autocomplete="off"
                name="filterValue"
                [(ngModel)]="filterValue"
                class="input-txt"
              />
              <input
                *ngIf="mustShowNumberInput()"
                #filterNumber
                (keyup.enter)="executeFilter()"
                (keyup.esc)="clearAndCloseFilterDropdown()"
                type="number"
                autocomplete="off"
                name="filterValue"
                [(ngModel)]="filterValue"
                class="input-txt"
              />
              <input
                *ngIf="mustShowDateInput()"
                #filterDate
                (keyup.enter)="executeFilter()"
                (keyup.esc)="clearAndCloseFilterDropdown()"
                type="text"
                [placeholder]="getDateInputFormat()"
                class="input-txt"
                #datepickerMDY="bsDatepicker"
                autocomplete="off"
                bsDatepicker
                name="filterValue"
                [bsConfig]="{ dateInputFormat: getDateInputFormat() }"
                [(ngModel)]="filterValue"
              />
            </div>
            <div class="buttons-container mt-2 left">
              <button class="btn-aux-ex btn-sm" (click)="clearFilter()">
                {{ "ClearFilter" | localize }}
              </button>
              <button class="btn-default btn-sm" (click)="executeFilter()">
                {{ "Apply" | localize }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="clearfix"></div>
  </th>
</ng-template>
