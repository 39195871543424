import { Attribute, Directive, Injector, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import * as moment from 'moment';

@Directive({
  selector: '[validateAfter][ngModel],[validateAfter][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfterValidator),
      multi: true
    }
  ]
})
export class AfterValidator extends AppComponentBase implements Validator {
  constructor(injector: Injector, @Attribute('validateAfter') public startDate: string) {
    super(injector);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const startDateControl = control.root.get(this.startDate);
    const endDateControl = control;

    if (!startDateControl || !endDateControl) {
      return null;
    }

    const startDate = moment(startDateControl.value, this.getDateInputFormat(), true);
    const endDate = moment(endDateControl.value, this.getDateInputFormat(), true);

    return startDate.isAfter(endDate, 'day') ? { validateAfter: true } : null;
  }
}
